

  import { computed, ref } from 'vue'
  import VaccinationRepository from "@/services/VaccinationRepository";
  import moment from 'moment';
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import {ThongTin} from "@/models/thongTin";
  import router from "@/router";
  import {useToast} from "primevue/usetoast";
  import {useStore} from "vuex";
  import {Ongtiem} from "@/models/ongtiem";
  import {RegisterService} from "@/models/registerService";
  import ZaloRepository from "@/services/ZaloRepository";
  import {ListService} from "@/models/listService";
  import {useRouter} from "vue-router";
  import {RegisterServiceUpdate} from "@/models/registerServiceUpdate";

  export default {
    setup() {
      const toast = useToast();
      const store = useStore();
      const list = ref([] as RegisterService[]);
      const listTemp = ref([] as RegisterService[]);
      const date = ref();
      const listService = ref([] as ListService[]);
      const service = ref("");
      const router = useRouter();
      const kind = ref(0);
      const updateReg = ref(false);
      const registerServiceUpdate = ref({} as RegisterServiceUpdate);
      const minDate = ref(new Date());

      const toTimestamp = (strDate) => {
        const dt = Date.parse(strDate);
        return dt / 1000;
      }
      const loadData = (id) => {
        updateReg.value = true;
        ZaloRepository.registerServiceById(id)
            .then((response) => {
              registerServiceUpdate.value = response.data;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      }


      const update = () => {
        console.log("#######$$$$$$$$$$$$$$$$$ update: ");
        console.log("#######$$$$$$$$$$$$$$$$$ update registerServiceUpdate.value.timeBooking: " + registerServiceUpdate.value.timeBooking);
        console.log("#######$$$$$$$$$$$$$$$$$ update registerServiceUpdate.value.timeBooking formatDate: " + formatDate(registerServiceUpdate.value.timeBooking));
        registerServiceUpdate.value.timeBooking = formatDate(registerServiceUpdate.value.timeBooking);
        ZaloRepository.updateRegisterService(registerServiceUpdate.value)
            .then((response) => {
              toast.add({
                severity: 'success',
                summary: 'Thành công',
                detail:'Chỉnh sửa khám thành công',
                life: 2500
              });
              selectCalendar();
              updateReg.value = false;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      }
      const valid = () => {
        return registerServiceUpdate.value.timeBooking;
      }
      const formatDate = (date) => {
        return moment(String(date)).format('YYYY-MM-DDTHH:mm:ss');
      };

      if(!(!!store.state.token)){
        router.push({
          name: 'home'
        });
      }

      const selectCalendar = () => {
        if((date.value[0] != null)&&(date.value[1] != null))
        {
          listTemp.value = [];
          list.value = [];
          ZaloRepository.getListRegister(date.value[0]/1000, date.value[1]/1000, kind.value)
              .then((response) => {
                listTemp.value = list.value = response.data;
                list.value.forEach(x => {
                  const check = ref(false);
                  listService.value.forEach(y => {
                    if(y.serviceCode == x.serviceCode)
                      check.value = true;
                  });
                  if(!check.value)
                  {
                    listService.value.push({
                      serviceCode: x.serviceCode,
                      serviceName: x.serviceName
                    });
                  }
                });
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })})
        }
      };

      //selectCalendar();

      const clearCalendar = () => {
        list.value = [];
        listService.value = [];
      }

      const formatDateTime = (date) => {
        if((date == null)||(date == ''))
        {
          return "chưa có thông tin"
        }
        else
        return moment(String(date)).format(' HH:mm DD/MM/YYYY');
      };

      const getFilter = () => {
        if((service.value != null)&&(service.value != ""))
        {
          list.value = listTemp.value.filter(x => x.serviceCode == service.value);
        }
        else{
          list.value = listTemp.value;
        }
      }
      const validDate = (registerService: RegisterService) => {
        return registerService.timeConfirm;
      }

      const clearDate = () => {
        console.log("@@@@@@@@@################ clearDate");
        var temp = ref();
        date.value = temp.value;
      }

      return {
        formatDateTime,
        list,
        date,
        selectCalendar,
        clearCalendar,
        listService,
        service,
        getFilter,
        validDate,
        kind,
        clearDate,
        updateReg,
        valid,
        loadData,
        registerServiceUpdate,
        update
      }
    }

  }
