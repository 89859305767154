
import {computed, ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";
import {UserDetail} from "@/models/userDetail";
import ZaloRepository from "@/services/ZaloRepository";
import {UserUpdate} from "@/models/userUpdate";
import {useConfirm} from "primevue/useconfirm";

export default {
  setup() {
    const toast = useToast();
    const list = ref([] as UserDetail[]);
    const store = useStore();
    const userUpdate = ref({} as UserUpdate);
    const updateReg = ref(false);

    if(!(store.state.permission == 'admin')){
      router.push({
        name: 'home'
      });
    }

    const userPermission = ref([
      {label: "Quản lý", value: "admin", param: 1},
      {label: "Nhân viên", value: "user", param: 2},
    ]);

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const activeStatus = (i) => {
      if(i)
      {
        return "Đang hoạt động";
      }
      else
        return "Đang tạm ngưng"
    };

    const loadData = () => {
      AuthRepository.getListUser()
          .then((response) => {
            list.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    };

    loadData();
    userUpdate.value.password = "";
    const loadDataUser = (id) => {
      AuthRepository.getUserById(id)
          .then((response) => {
            updateReg.value = true;
            userUpdate.value = response.data;
            userUpdate.value.password = "";
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const valid = computed(()=> userUpdate.value.password && userUpdate.value.username && userUpdate.value.fullname);

    if(!(store.state.permission == 'admin')){
      router.push({
        name: 'home'
      });
    }

    const doUpdate = () => {
      console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222 userUpdate.value.password.length: " + JSON.stringify(userUpdate.value.password));
      console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222 userUpdate.value.password. check value: " + (userUpdate.value.password == null));

      const checkValue = ref(false);
      if(userUpdate.value.password == null){
        checkValue.value = true;
      }
      else{
        if(userUpdate.value.password.length < 6 && userUpdate.value.password.length > 0)
        {
          checkValue.value = false;
          toast.add({
            severity: 'error',
            summary: 'Thất bại',
            detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
            life: 2000
          });
        }
        else{
          checkValue.value = true;
        }
      }

      if(checkValue.value) {
        AuthRepository.updateUser(userUpdate.value)
            .then((response) => {
              console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222: " + JSON.stringify(userUpdate.value));
              toast.add({
                severity: 'success',
                summary: 'Cập nhật',
                detail: 'Cập nhật thông tin tài khoản thành công',
                life: 2000
              });
              loadData()
              updateReg.value = false;
              // router.push({
              //   name: 'home'
              // });
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      };
    };
    const confirm = useConfirm();
    const del = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá tài khoản này không?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          AuthRepository.deleteUser(userUpdate.value.id)
              .then(response => {
                toast.add({severity:'info', summary:'Confirmed', detail:'Tài khoản đã được xoá thành công', life: 3000});
                loadData()
                updateReg.value = false;
              })
              .finally(()=>{
              });
        },
        reject: () => {
        }
      });
    };

    return {
      list,
      filters,
      clearFilter,
      initFilters,
      activeStatus,
      updateReg,
      userUpdate,
      loadDataUser,
      userPermission,
      doUpdate,
      del
    }
  }
}
