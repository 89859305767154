
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const permission = ref(false);
    const appMenu = computed(() => {
      console.log("@@@@@#############$$$$$$$$$$$$$ check appMenu: ");
      const menu = ref([
        // {
        //   label:'Gởii tin nhắn',
        //   icon:'pi pi-fw pi-send',
        //   href: '/sendmessage'
        // },
        // {
        //   label:'Đăng hình ảnh',
        //   icon:'pi pi-fw pi-upload',
        //   to: '/uploadImage'
        // },
        {
          label:'Danh sách bệnh nhân đăng ký khám bệnh',
          icon:'pi pi-fw pi-list',
          to: '/listRegisterService'
        },
        {
          label:'Thêm nhân viên hỗ trợ',
          icon:'pi pi-fw pi-user-plus',
          to: '/addemployee'
        },
        {
          label:'Danh sách nhân viên',
          icon:'pi pi-fw pi-users',
          to: '/showlistemployee'
        },
      ]);
      const menuDefault = ref([
      ]);
      console.log("@@@@@#############$$$$$$$$$$$$$ check appMenu 222222222: ");
      // if(!!store.state.token)
      //   return menu.value;
      // else
      //   return menuDefault.value;
      console.log("@@@@@#############$$$$$$$$$$$$$ check appMenu 333333333333: ");
console.log("@@@@@#############$$$$$$$$$$$$$ check permission: " + permission.value);

      if(store.state.permission == "admin")
      {
        console.log("@@@@@#############$$$$$$$$$$$$$ check permission admin: " );
        permission.value = true;
      }
      else
      {
        console.log("@@@@@#############$$$$$$$$$$$$$ check permission user: " );
        permission.value = false;
      }
    });
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }
    const gotoLoginPage = () => {
      router.push({
        name: 'login',
      });
    }

    return {
      appMenu,
      logout,
      gotoLoginPage,
      permission
    }
  }
}
