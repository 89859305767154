

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import {SubclinicalResults} from "@/models/subclinicalResults";
import zaloRepository from "@/services/ZaloRepository";
import moment from "moment";

export default {
  props: {
    svv: String,
    sdt: String,
  },

  setup(props){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listSubclinicalResults = ref([] as SubclinicalResults[]);
    const subclinicalResults = ref({} as SubclinicalResults);
    const date = ref("");


    const formatDateTime = (date) => {
      console.log("########################## formatDateTime: " + moment(String(date)).format('DD/MM/YYYY'));
      return moment(String(date)).format('DD/MM/YYYY');
    };
      zaloRepository.getListSubclinicalResult(props.svv,props.sdt)
          .then((response) => {
            listSubclinicalResults.value = response.data;
            // console.log("############$$$$$$$$$$$$$$$$$$$ listSubclinicalResults: " + JSON.stringify(listSubclinicalResults.value));
            subclinicalResults.value = listSubclinicalResults.value[0];
            date.value = formatDateTime(subclinicalResults.value.ngayYeuCau);
            // console.log("############$$$$$$$$$$$$$$$$$$$ subclinicalResults: " + JSON.stringify(subclinicalResults.value));
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });


    return {
      subclinicalResults,
      listSubclinicalResults,
      formatDateTime,
      date
    }
  }
}

